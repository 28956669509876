<template>
  <div class="container-fluid">
    <div class="row match-height">
      <div class="col-xl-6 align-items-stretch">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Barcode Template
            </h4>
            <div class="heading-elements">
              <ul class="list-inline">
                <li>
                  <dx-util-select-box
                  :data-source="templateSettings"
                  display-expr="value.templateName"
                  @value-changed="onChangeSetSelectedTemplate"
                />
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="text" class="form-label">Code Type</label>
                  <dx-util-select-box
                    v-model="barcodeForm.format"
                    :data-source="formats"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="text" class="form-label">Barcode</label>
                  <dx-util-text-box
                    v-model="barcodeText"
                    placeholder="Text"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="text" class="form-label">Text</label>
                  <dx-util-text-box
                    v-model="barcodeForm.text"
                    placeholder="Text"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="text" class="form-label">Background Color</label>
                  <dx-color-box
                    v-model="barcodeForm.background"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="text" class="form-label">Line Color</label>
                  <dx-color-box
                    v-model="barcodeForm.lineColor"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="text" class="form-label">Font</label>
                  <dx-util-select-box
                    v-model="barcodeForm.font"
                    :data-source="fonts"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="text" class="form-label">Font Size</label>
                  <dx-slider
                    v-model="barcodeForm.fontSize"
                    :min="6"
                    :max="256"
                    :tooltip="tooltip"
                    @value-changed="generate"
                  />
                </div>
              </div>
              <div class="col-md-3">
            <div class="form-group">
              <label for="text" class="form-label">Show Text</label>
              <dx-radio-group
                v-model="barcodeForm.displayValue"
                :data-source="displayValues"
                layout="horizontal"
                display-expr="text"
                value-expr="value"
                @value-changed="generate"
              />
            </div>
        </div>
            <div class="col-md-3">
                <div class="form-group">
              <label for="text" class="form-label">Font Options</label>
              <div>
                <DxCheckBox
                  v-model="isBold"
                  :icon-size="24"
                  :element-attr="{ class: 'mr-1' }"
                  text="Bold"
                  @value-changed="onChangeFontStyle"
                />
                <DxCheckBox
                  v-model="isItalic"
                  :icon-size="24"
                  text="Italic"
                  @value-changed="onChangeFontStyle"
                />
              </div>
            </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
              <label for="text" class="form-label">Text Align</label>
              <dx-radio-group
                v-model="barcodeForm.textAlign"
                :data-source="textAligns"
                layout="horizontal"
                display-expr="text"
                value-expr="value"
                @value-changed="generate"
              />
            </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
              <label for="text" class="form-label">Text Position</label>
              <dx-radio-group
                v-model="barcodeForm.textPosition"
                :data-source="textPositions"
                layout="horizontal"
                display-expr="text"
                value-expr="value"
                @value-changed="generate"
              />
            </div>
            </div>
            </div>
            <div class="form-group">
              <label for="text" class="form-label">Bar Width</label>
              <dx-slider
                v-model="barcodeForm.width"
                :min="0"
                :max="10"
                :tooltip="tooltip"
                @value-changed="generate"
              />
            </div>
            <div class="form-group">
              <label for="text" class="form-label">Height</label>
              <dx-slider
                v-model="barcodeForm.height"
                :min="20"
                :max="1200"
                :tooltip="tooltip"
                @value-changed="generate"
              />
            </div>

            <div class="form-group">
              <label for="text" class="form-label">Text Margin</label>
              <dx-slider
                v-model="barcodeForm.textMargin"
                :min="-10"
                :max="100"
                :tooltip="tooltip"
                @value-changed="generate"
              />
            </div>

            <div class="form-group">
              <label for="text" class="form-label">Margin</label>
              <dx-slider
                v-model="barcodeForm.margin"
                :min="0"
                :max="250"
                :tooltip="tooltip"
                @value-changed="onChangeMargin"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 align-items-stretch">
        <div class="card">
            <div class="card-header">
            <h4 class="mb-0">
              Barcode Template Preview
            </h4>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                    <dx-util-button
                    type="default"
                    icon="download"
                    @click="download"
                  />
                </li>
              </ul>
            </div>
          </div>
            <div class="card-body d-flex align-items-center justify-content-center">
                <svg id="barcodePreview" ref="barcodePreviewRef"></svg>
            </div>
            <div class="card-footer text-right">
            <dx-util-button
              icon="save"
              text="Save Template"
              type="success"
              styling-mode="contained"
              width="250"
              :visible="!isUpdate"
              @click="createBarcodeTemplateSetting"
            />
            <dx-util-button
              icon="save"
              text="Update Template"
              type="success"
              styling-mode="contained"
              width="250"
              :visible="isUpdate"
              @click="updateBarcodeTemplateSetting"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable consistent-return */

import { DxSlider } from 'devextreme-vue/slider'
import DxRadioGroup from 'devextreme-vue/radio-group'
import { DxCheckBox } from 'devextreme-vue/check-box'
import DxColorBox from 'devextreme-vue/color-box'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import tenantService from '@/http/requests/tenant/tenan.settings'
import useCurrentUser from '@/libs/app/current-user'
import { jsPDF } from 'jspdf'

const JsBarcode = require('jsbarcode')

const formats = [
  { value: 'CODE128', text: 'CODE128' },
  { value: 'CODE128A', text: 'CODE128A' },
  { value: 'CODE128B', text: 'CODE128B' },
  { value: 'CODE128C', text: 'CODE128C' },
  { value: 'EAN13', text: 'EAN13' },
  { value: 'EAN8', text: 'EAN8' },
  { value: 'UPC', text: 'UPC' },
  { value: 'ITF14', text: 'ITF14' },
  { value: 'ITF', text: 'ITF' },
  { value: 'MSI', text: 'MSI' },
  { value: 'MSI10', text: 'MSI10' },
  { value: 'MSI11', text: 'MSI11' },
  { value: 'MSI1010', text: 'MSI1010' },
  { value: 'MSI1110', text: 'MSI1110' },
  { value: 'pharmacode', text: 'pharmacode' },
]

const displayValues = [
  { text: 'Show', value: true },
  { text: 'Hide', value: false },
]

const textAligns = [
  { text: 'Left', value: 'left' },
  { text: 'Center', value: 'center' },
  { text: 'Right', value: 'right' },
]

const textPositions = [
  { text: 'Top', value: 'top' },
  { text: 'Bottom', value: 'bottom' },
]

const fonts = [
  { text: 'Monospace', value: 'monospace' },
  { text: 'Sans-Serif', value: 'sans-serif' },
  { text: 'Serif', value: 'serif' },
  { text: 'Fantasy', value: 'fantasy' },
  { text: 'Cursive', value: 'cursive' },
]
const formatSlider = value => `${value}`

export default {
  components: {
    DxSlider,
    DxRadioGroup,
    DxCheckBox,
    DxColorBox,
  },
  setup() {
    const {
      userCompanyId,
      userTenantId,
    } = useCurrentUser()

    return {
      userCompanyId,
      userTenantId,
    }
  },
  data() {
    return {
      JsBarcode,
      formats,
      fonts,
      barcodeText: 'PrepShipHub',
      displayValues,
      textAligns,
      textPositions,
      isBold: false,
      isItalic: false,
      barcodeForm: {
        format: 'CODE128',
        width: 2,
        height: 100,
        displayValue: true,
        text: 'PrepShipHub',
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 20,
        background: '#ffffff',
        lineColor: '#000000',
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10,
      },
      barcodeFormDefault: {},
      tooltip: {
        enabled: true,
        showMode: 'onHover',
        position: 'top',
        formatSlider,
      },
      templateSetting: null,
      templateSettings: [],
    }
  },
  computed: {
    isUpdate() {
      return !!(this.templateSetting && this.templateSetting.id)
    },
  },
  watch: {
    barcodeText(newValue) {
      this.barcodeForm.text = newValue
    },
  },
  mounted() {
    this.generate()
    this.getTemplateTenantSettings()
    Object.assign(this.barcodeFormDefault, this.barcodeForm)
  },
  methods: {
    generate() {
      const target = this.$refs.barcodePreviewRef
      const data = { ...this.barcodeForm }
      const barcodeText = this.barcodeText
      this.$nextTick(async () => {
        this.JsBarcode(`#${target.id}`, barcodeText, data)
      })
    },
    onChangeFontStyle() {
      if (this.isBold && this.isItalic) this.barcodeForm.fontOptions = 'bold italic'
      if (this.isBold && !this.isItalic) this.barcodeForm.fontOptions = 'bold'
      if (!this.isBold && this.isItalic) this.barcodeForm.fontOptions = 'italic'
      if (!this.isBold && !this.isItalic) this.barcodeForm.fontOptions = ''
      this.generate()
    },
    onChangeMargin() {
      this.barcodeForm.marginTop = this.barcodeForm.margin
      this.barcodeForm.marginRight = this.barcodeForm.margin
      this.barcodeForm.marginBottom = this.barcodeForm.margin
      this.barcodeForm.marginLeft = this.barcodeForm.margin
      this.generate()
    },
    async download() {
      const target = document.getElementById('barcodePreview')
      const imageWidth = target.width.animVal.value
      const imageHeight = target.height.animVal.value

      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [imageWidth, imageHeight],
        hotfixes: ['px_scaling'],
        precision: 0,
        userUnit: 72,
        margins: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      })

      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()

      const ratio = Math.min(pageWidth / imageWidth, pageHeight / imageHeight)
      const x = (pageWidth - (imageWidth * ratio)) / 2
      const y = (pageHeight - (imageHeight * ratio)) / 2

      const svgAsText = new XMLSerializer().serializeToString(target)
      const w = imageWidth * ratio
      const h = imageHeight * ratio
      await doc.addSvgAsImage(svgAsText, x, y, w, h)
      doc.save('barcode-template.pdf')
    },
    // Get Tenant Settings
    getTemplateTenantSettings() {
      tenantService.findByKeyAndCurrentTenantId(managedKeysEnum.BARCODE_TEMPLATE.key).then(result => {
        const { data } = result
        this.templateSettings.length = 0
        this.templateSettings.push({
          id: null,
          value: {
            templateName: 'Create New Template',
          },
        })
        data.forEach(item => {
          const value = JSON.parse(item.value)
          const setting = {
            id: item.id,
            value: value,
            key: item.key,
            referenceId: item.referenceId,
            tenantId: item.tenantId,
            valueType: item.valueType,
          }
          this.templateSettings.push(setting)
        })
      })
    },
    onChangeSetSelectedTemplate(e) {
      this.templateSetting = e.value
      if (this.templateSetting && this.templateSetting.id) {
        Object.assign(this.barcodeForm, this.templateSetting.value.templateSettings)
      } else {
        Object.assign(this.barcodeForm, this.barcodeFormDefault)
      }
    },
    // Save Barcode Templates Tenant Setting
    async createBarcodeTemplateSetting(e) {
      const { value: templateName } = await this.$swal({
        text: 'Create Barcode Template',
        input: 'text',
        inputLabel: 'Template Name',
        inputValue: '',
        inputValidator: value => {
          if (!value) {
            return 'Template Name field is required!'
          }
        },
        showCancelButton: true,
        confirmButtonText: 'Create',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
      })
      if (templateName) {
        let data = {
          templateName: templateName,
          templateSettings: { ...this.barcodeForm },
        }

        data = JSON.stringify(data)
        const templateSetting = {
          id: null,
          key: managedKeysEnum.BARCODE_TEMPLATE.key,
          value: data,
          tenantId: this.userTenantId,
          scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
          valueType: valueTypeEnum.JSON.key,
          version: null,
          referenceId: this.userCompanyId,
        }
        tenantService
          .create(templateSetting)
          .then(() => {
            this.getTemplateTenantSettings()
          })
      }
    },
    async updateBarcodeTemplateSetting() {
      const self = this
      const { value: templateName } = await this.$swal({
        text: 'Update Barcode Template',
        input: 'text',
        inputLabel: 'Template Name',
        inputValue: self.templateSetting.value.templateName,
        inputValidator: value => {
          if (!value) {
            return 'Template Name field is required!'
          }
        },
        showCancelButton: true,
        confirmButtonText: 'Update',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
      })
      if (templateName) {
        let data = {
          templateName: templateName,
          templateSettings: { ...this.barcodeForm },
        }

        data = JSON.stringify(data)
        const templateSetting = {
          id: this.templateSetting.id,
          key: this.templateSetting.key,
          value: data,
          tenantId: this.userTenantId,
          scopeType: this.templateSetting.scopeType,
          valueType: this.templateSetting.valueType,
          version: null,
          referenceId: this.userCompanyId,
        }
        tenantService
          .update(templateSetting)
          .then(() => {
            this.getTemplateTenantSettings()
          })
      }
    },
  },
}
</script>
